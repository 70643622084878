import { Component, OnInit } from '@angular/core';
import { MatSnackBarRef } from '@angular/material';
import { NotificationMessage, NotificationService, NotificationTypeEnum } from '@xpo-ltl/data-api';
import { XpoNotificationTemplate, XpoSnackBar, XpoSnackBarStatus } from '@xpo/ngx-core';
import { TransitTimeService } from '../shared/services/transit-time.service';
@Component({
  selector: 'app-postal-codes',
  templateUrl: './postal-codes.component.html',
  styleUrls: ['./postal-codes.component.scss'],
})
export class PostalCodesComponent implements OnInit {
  private snackBarRef: MatSnackBarRef<XpoNotificationTemplate>;

  constructor(private serviceTransitTime: TransitTimeService, private snackbar: XpoSnackBar) {}

  ngOnInit() {}

  checkMaxPostalCodes(): boolean {
    let response = false;
    if (this.serviceTransitTime.getPostalCodesListLength() >= 10) {
      response = true;
    }

    return response;
  }

  getPostalCodes(): string[] {
    return this.serviceTransitTime.getPostalCodes();
  }

  omit_special_char(event) {
    let k;
    k = event.charCode;
    /*
    Characters allowed:
    48 = 0, 49 = 1, 50 = 2, 51 = 3, 52 = 4, 53 = 5, 54 = 6, 55 = 7,
    56 = 8, 57 = 9, 65 = A, 66 = B, 67 = C, 68 = D, 69 = E, 70 = F,
    71 = G, 72 = H, 73 = I, 74 = J, 75 = K, 76 = L, 77 = M, 78 = N,
    79 = O, 80 = p, 81 = Q, 82 = R, 83 = S, 84 = T, 85 = U, 86 = V,
    87 = W, 88 = X, 89 = Y, 90 = Z, 97 = a, 98 = b, 99 = c, 100 = d,
    101 = e, 102 = f, 103 = g, 104 = h, 105 = i, 106 = j, 107 = k,
    108 = l, 109 = m, 110 = n, 111 = o, 112 = p, 113 = q, 114 = r,
    115 = s, 116 = t, 117 = u, 118 = v, 119 = w, 120 = x, 121 = y,
    122 = z
    */
    return (k > 64 && k < 91) || (k > 96 && k < 123) || (k >= 48 && k <= 57);
  }

  addPostalCode(postalCode: string): void {
    const patt = new RegExp(/^[A-Za-z0-9]+$/g);
    if (postalCode === '') {
      this.snackBarRef = this.snackbar.open({
        message: 'The zip code can not be empty',
        status: 'error' as XpoSnackBarStatus,
        matConfig: {
          duration: 5000,
        },
      });
    } else if (patt.test(postalCode) === false) {
      this.snackBarRef = this.snackbar.open({
        message: 'The zip code can not have special characters',
        status: 'error' as XpoSnackBarStatus,
        matConfig: {
          duration: 5000,
        },
      });
    } else {
      this.serviceTransitTime.addPostalCode(postalCode);
    }
  }
}
