import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { LoginService } from '@xpo-ltl/login';
import { User } from '@xpo-ltl/sdk-common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppRoutes } from '../../enums/app-routes.enum';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
import { UserRoleService } from '../../services/user-role/user-role.service';

@Injectable({
  providedIn: 'root'
})
export class NotAuthorizedRedirectGuard implements CanActivate {
  constructor(
    private loginService: LoginService,
    private config: ConfigManagerService,
    private router: Router,
    private userRoleService: UserRoleService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.loginService
      .getLoggedInUser(
        this.config.getSetting(ConfigManagerProperties.loggedInUserRoot)
      )
      .pipe(
        map((user: User) => {
          if (this.userRoleService.isAuthorizedUser(user)) {
            this.userRoleService.user = user;
            this.router.navigate([AppRoutes.TRANSIT_TIME_FILE_FORM]);
            return true;
          }
          this.userRoleService.user = undefined;
          return false;
        })
      );
  }
}
