import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBarRef } from '@angular/material';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { NotificationMessage, NotificationService, NotificationTypeEnum } from '@xpo-ltl/data-api';
import { LoginService } from '@xpo-ltl/login';
import { XpoNotificationTemplate, XpoSnackBar, XpoSnackBarStatus } from '@xpo/ngx-core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { FileRequestForm } from '../../../../file-request-form';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class TransitTimeService {
  private snackBarRef: MatSnackBarRef<XpoNotificationTemplate>;
  private url: string;
  private formData: FileRequestForm = new FileRequestForm();
  private loggedInUserEmail: string;
  private country: string;

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private configService: ConfigManagerService,
    private snackbar: XpoSnackBar
  ) {}

  getApiUrl(): string {
    if (!this.url) {
      const apiUrl = this.configService.getSetting('apiUrl');
      /*TODO: Refactor pricingApiEndpoint by salessupportApiEndpoint*/
      const pricingApiEndpoint = this.configService.getSetting('pricingApiEndpoint');
      const transitTimeFileApiEndpoint = this.configService.getSetting('transitTimeFileApiEndpoint');
      this.url = `${apiUrl}${pricingApiEndpoint}${transitTimeFileApiEndpoint}`;
    }

    return this.url;
  }

  addPostalCode(postalCode: string): void {
    this.formData.postalCode.push(postalCode);
  }

  setLoggedInUserEmail(email: string): void {
    this.loggedInUserEmail = email;
    this.setRequesterEmail(email);
  }

  getLoggedInUserEmail(): string {
    if (this.loggedInUserEmail) {
      return this.loggedInUserEmail;
    } else {
      let key = sessionStorage.key(1);
      for (let i = 0, t = sessionStorage.length; i < t; i++) {
        key = sessionStorage.key(i);
        if (key.startsWith('oidc.user')) {
          const valueKey = JSON.parse(sessionStorage[key]);
          this.loggedInUserEmail = valueKey.profile.email;
        }
      }
      return this.loggedInUserEmail;
    }
  }

  setRequesterEmail(email: string): void {
    this.formData.requestorEmailId = email;
  }

  getRequesterEmail(): string {
    return this.formData.requestorEmailId;
  }

  setDirection(direction: string): void {
    this.formData.directionCode = direction;
  }

  setFileFormat(fileFormat: string): void {
    this.formData.fileFormat = fileFormat;
  }

  getPostalCodes(): string[] {
    return this.formData.postalCode;
  }

  getPostalCodesListLength(): number {
    return this.formData.postalCode.length;
  }

  setCountry(country: string): void {
    this.formData.countryCode = country;
  }

  getCountry(): string {
    return this.formData.countryCode;
  }

  /** POST: add a new transit time file request */
  postTransitTime() {
    const asJson = JSON.stringify(this.formData);
    return this.loginService.getAccessToken().subscribe((token) => {
      httpOptions.headers.append('Authorization', `Bearer ${token}`);
      httpOptions.headers.append('Content-Type', `application/json`);
      return this.http.post<string>(this.getApiUrl(), asJson, httpOptions).subscribe(
        (res) => {
          this.formData.postalCode = [];
          this.snackBarRef = this.snackbar.open({
            message: 'The Request is received and the file will be emailed',
            status: 'success' as XpoSnackBarStatus,
            matConfig: {
              duration: 5000,
            },
          });
        },
        (error) => {
          this.snackBarRef = this.snackbar.open({
            message: error.error.error.message,
            status: 'error' as XpoSnackBarStatus,
            matConfig: {
              duration: 5000,
            },
          });
        }
      );
    });
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      /*
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
      */
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
