import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { AuthXpoModule } from '@xpo-ltl/auth-xpo';
import { CONFIG_MANAGER_SERVICE_CONFIGURATION, ConfigManagerModule } from '@xpo-ltl/config-manager';
import { DataApiService, NotificationService } from '@xpo-ltl/data-api';
import { LoginModule } from '@xpo-ltl/login';
import { SdkAppMetadataModule } from '@xpo-ltl/sdk-appmetadata';
import { SdkInfrastructureModule } from '@xpo-ltl/sdk-infrastructure';
import { SdkLocationModule } from '@xpo-ltl/sdk-location';
import { SdkLoggingModule } from '@xpo-ltl/sdk-logging';
import { SdkUserPreferenceModule } from '@xpo-ltl/sdk-userpreference';
import {
  XpoAccountPopoverModule,
  XpoAppSwitcherPopoverModule,
  XpoButtonModule,
  XpoCardModule,
  XpoDialogModule,
  XpoFeedbackModule,
  XpoShellModule,
  XpoSnackBarModule,
} from '@xpo/ngx-core';
import { XpoLtlReleaseNotesComponent, XpoLtlReleaseNotesModule } from '@xpo/ngx-ltl';
import { NotificationComponent } from './app-notification.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { DialogModule } from './dialogs/dialog.module';
import { SharedModule } from './shared/shared.module';
import { TransitTimeFileFormModule } from './transit-time-file-form/transit-time-file-form.module';

@NgModule({
  declarations: [AppComponent, NotificationComponent],
  imports: [
    BrowserModule,
    ConfigManagerModule,
    LoginModule,
    DialogModule,
    SharedModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    XpoAccountPopoverModule,
    XpoAppSwitcherPopoverModule,
    XpoLtlReleaseNotesModule,
    XpoButtonModule,
    XpoCardModule,
    XpoDialogModule,
    XpoShellModule,
    XpoFeedbackModule,
    XpoSnackBarModule,
    SdkAppMetadataModule,
    SdkLocationModule,
    SdkUserPreferenceModule,
    SdkInfrastructureModule,
    SdkLoggingModule,
    TransitTimeFileFormModule,
    CoreModule,
    AppRoutingModule, // Keep last
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    DataApiService,
    NotificationService,
  ],
  entryComponents: [XpoLtlReleaseNotesComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
