import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConfirmComponent } from '../dialogs/confirm/confirm.component';
import { TransitTimeService } from './components/shared/services/transit-time.service';

@Component({
  selector: 'app-transit-time-file-form',
  templateUrl: './transit-time-file-form.component.html',
  styleUrls: ['./transit-time-file-form.component.scss'],
})
export class TransitTimeFileFormComponent implements OnInit {
  requesterEmail: string;
  direction: string;
  fileFormat: string;
  inbound: boolean;
  outbound: boolean;
  repAllow: boolean = true;
  countryUs: boolean;
  countryCa: boolean;

  constructor(private serviceTransitTime: TransitTimeService, private confirmDialog: MatDialog) {}

  ngOnInit() {
    this.resetForm();
  }

  validateForm() {
    // tslint:disable-next-line:max-line-length
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      this.fileFormat !== '' &&
      this.requesterEmail.trim() !== '' &&
      this.serviceTransitTime.getPostalCodes().length > 0 &&
      this.serviceTransitTime.getPostalCodes().length <= 10 &&
      (this.inbound === true || this.outbound === true) &&
      (this.countryUs === true || this.countryCa === true) &&
      re.test(this.requesterEmail.trim())
    ) {
      return true;
    } else {
      return false;
    }
  }

  formatDirection(inbound: boolean, outbound: boolean) {
    if (inbound === true && outbound === true) {
      return 'Both';
    } else if (inbound === true && outbound === false) {
      return 'Inbound';
    } else if (inbound === false && outbound === true) {
      return 'Outbound';
    }
  }

  checkFormSubmitAction(): void {
    const uniquePostalCodes = new Set(this.serviceTransitTime.getPostalCodes());

    if (uniquePostalCodes.size < this.serviceTransitTime.getPostalCodes().length) {
      this.showConfirmDialog();
    } else {
      this.submitForm();
    }
  }

  showConfirmDialog(): void {
    this.repAllow = false;
    const dialogRef = this.confirmDialog.open(ConfirmComponent, {
      width: '350px',
      data: 'There are duplicated postal codes, do you want to submit anyway?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.repAllow = true;
        this.submitForm();
      } else {
        this.repAllow = true;
      }
    });
  }

  submitForm(): void {
    // Add multiple values of the checkbox here
    this.serviceTransitTime.setDirection(this.formatDirection(this.inbound, this.outbound));
    this.serviceTransitTime.setRequesterEmail(this.requesterEmail);
    this.serviceTransitTime.setFileFormat(this.fileFormat);
    this.serviceTransitTime.setCountry(this.getCountryCode());
    this.serviceTransitTime.postTransitTime();

    this.resetForm();
  }

  resetForm(): void {
    this.requesterEmail = this.serviceTransitTime.getLoggedInUserEmail();
    this.direction = '';
    this.fileFormat = 'CSV';
    this.inbound = false;
    this.outbound = true;
    this.countryUs = true;
    this.countryCa = false;
  }

  private getCountryCode(): string {
    let countryCode = '';

    if (this.countryUs && !this.countryCa) {
      countryCode = 'US';
    } else if (!this.countryUs && this.countryCa) {
      countryCode = 'CA';
    }

    return countryCode;
  }
}
