import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatDividerModule,
  MatFormFieldModule,
  MatGridListModule,
  MatInputModule,
  MatListModule,
  MatOptionModule,
  MatRadioModule,
  MatSelectModule,
} from '@angular/material';
import { XpoCardModule } from '@xpo/ngx-core';
// import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from '../shared/shared.module';
import { PostalCodesComponent } from './components/postal-codes/postal-codes.component';
import { TransitTimeFileFormComponent } from './transit-time-file-form.component';

@NgModule({
  declarations: [TransitTimeFileFormComponent, PostalCodesComponent],
  imports: [
    CommonModule,
    SharedModule,
    XpoCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatInputModule,
    MatRadioModule,
    MatOptionModule,
    MatSelectModule,
    MatButtonModule,
    MatGridListModule,
    MatListModule,
    MatDividerModule,
    // ToastrModule.forRoot(),
  ],
})
export class TransitTimeFileFormModule {}
