import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { NotAuthorizedComponent } from './dialogs/not-authorized/not-authorized.component';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { NotAuthorizedRedirectGuard } from './shared/guards/not-authorized-redirect/not-authorized-redirect.guard';
import { TransitTimeFileFormComponent } from './transit-time-file-form/transit-time-file-form.component';

const routerDefinitions: Routes = [
  {
    path: AppRoutes.NOT_AUTHORIZED_PAGE,
    component: NotAuthorizedComponent,
    canActivate: [NotAuthorizedRedirectGuard],
  },
  {
    path: AppRoutes.TRANSIT_TIME_FILE_FORM,
    component: TransitTimeFileFormComponent,
    canActivate: [XpoAuthenticationGuard],
  },
  // ADD ANY NEW ROUTE / PAGE HERE
  {
    path: '**',
    redirectTo: AppRoutes.TRANSIT_TIME_FILE_FORM,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routerDefinitions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
